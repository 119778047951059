import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, HashRouter as Router } from 'react-router-dom'

import Navbar from '../components/Navbar/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Home2 from '../components/Home2/Main'
import Home3 from '../components/Home3/Main'
import About from '../components/About/Main'
import Service from '../components/Services/Service/Main'
import ServiceDetails from '../components/Services/ServiceDetails/Main'
import Team from '../components/Teams/Team/Main'
import TeamDetails from '../components/Teams/TeamDetails/Main'
import CreditAudit from '../components/Credit/CreditAudit/Main'
import CreditRepair from '../components/Credit/CreditRepair/Main'
import Careers from '../components/Careers/Main'
import Faqs from '../components/Faqs/Main'
import ApplyNow from '../components/ApplyNow/Main'
import CompareEMI from '../components/Features/CompareEMI/Main'
import HomeLoan from '../components/Features/HomeLoan/Main'
import HomeLoanEligibility from '../components/Features/HomeLoanEligibility/Main'
import LoanEligibility from '../components/Features/LoanEligibility/Main'
import MonthlyLoan from '../components/Features/MonthlyLoan/Main'
import PersonalLoan from '../components/Features/PersonalLoan/Main'
import NewsMain from '../components/News/NewsMain/Main'
import NewsDetails from '../components/News/NewsDetails/Main'
import Contact from '../components/Contact/Main'
import ServiceDetails2 from '../components/Services/ServiceDetails2/Main'
import ServiceDetails3 from '../components/Services/ServiceDetails3/Main'
import ServiceDetails4 from '../components/Services/ServiceDetails4/Main'
import ServiceDetails5 from '../components/Services/ServiceDetails5/Main'
import ServiceDetails6 from '../components/Services/ServiceDetails6/Main'
import ServiceDetails7 from '../components/Services/ServiceDetails7/Main'
import ServiceDetails8 from '../components/Services/ServiceDetails8/Main'
import ServiceDetails9 from '../components/Services/ServiceDetails9/Main'

import Course1 from '../components/Services/Course1/Main'
import Course2 from '../components/Services/Course2/Main'
import Course3 from '../components/Services/Course3/Main'
import Course4 from '../components/Services/Course4/Main'

import Calculator from '../components/Calculator/Main'
import ServiceNew from '../components/Service/Main'
import IELTS from '../components/ielts/Main'

import Privacy from '../components/Privacy/Main'


const Routing = () => {
  const [homepage, sethomepage] = useState(false)
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/home-02" || location.pathname === "/home-03") {
      sethomepage(false)
    } else {
      sethomepage(true)
    }
  }, [location])

  useEffect(() => {

    caches.keys().then((names) => {
      names.forEach((name) => {
        console.log(">>cache", name)
        caches.delete(name);
      });
    });



  }, [])

  return (
    <>
      {homepage && <Navbar />}


      <Routes>
        <Route path="/" element={<Home3 />} />
        <Route path="/home-02" element={<Home2 />} />
        <Route path="/home-03" element={<Home3 />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Service />} />
        <Route path="/services" element={<ServiceNew />} />
        <Route path="/servicedetails" element={<ServiceDetails />} />
        <Route path="/team" element={<Team />} />
        <Route path="/teamdetails" element={<TeamDetails />} />
        <Route path="/creditaudit" element={<CreditAudit />} />
        <Route path="/creditrepair" element={<CreditRepair />} />
        <Route path="/visa" element={<Careers />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/applynow" element={<ApplyNow />} />
        <Route path="/compareEMI" element={< CompareEMI />} />
        <Route path="/homeloan" element={< HomeLoan />} />
        <Route path="/homeloaneligibility" element={<HomeLoanEligibility />} />
        <Route path="/loaneligibility" element={<LoanEligibility />} />
        <Route path="/monthlyloan" element={<MonthlyLoan />} />
        <Route path="/personalloan" element={<PersonalLoan />} />
        <Route path="/newsmain" element={<NewsMain />} />
        <Route path="/newsdetails" element={<NewsDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/servicedetails2" element={<ServiceDetails2 />} />
        <Route path="/servicedetails3" element={<ServiceDetails3 />} />
        <Route path="/servicedetails4" element={<ServiceDetails4 />} />
        <Route path="/servicedetails5" element={<ServiceDetails5 />} />
        <Route path="/servicedetails6" element={<ServiceDetails6 />} />
        <Route path="/servicedetails7" element={<ServiceDetails7 />} />
        <Route path="/servicedetails8" element={<ServiceDetails8 />} />
        <Route path="/servicedetails9" element={<ServiceDetails9 />} />
        <Route path="/essentials" element={<Calculator />} />
        <Route path="/course1" element={<Course1 />} />
        <Route path="/course2" element={<Course2 />} />
        <Route path="/course3" element={<Course3 />} />
        <Route path="/course4" element={<Course4 />} />
        <Route path="/ielts" element={<IELTS />} />
        <Route path="/privacy" element={<Privacy />} />

      </Routes>

      <Footer />
    </>
  )
}

export default Routing
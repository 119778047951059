import BGPH1 from '../../assets/images/backgrounds/bg-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { postContacts } from '../../Services/API/Post'

import MAP1 from '../../assets/images/contact/map1.png'
import MAP2 from '../../assets/images/contact/map2.png'


const Contact = () => {

    const [msg, setmsg] = React.useState(null)

    const [firstName, setfirstName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [mobile, setmobile] = useState("");

    const [errfirstName, seterrfirstName] = useState(false);
    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);
    const [erremail, seterremail] = useState(false);
    const [errmobile, seterrmobile] = useState("");


    React.useEffect(() => {
        if (msg !== null) {
            setTimeout(() => {
                setmsg(null);
            }, 6000);
        }

    }, [msg])

    const contactUs = (event) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


        event.preventDefault();

        const obj = {
            name: firstName,
            email: email,
            subject: subject,
            message: message,
            contactNumber: mobile,
        };

        console.log("obj", obj);

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (subject === "") {
            seterrsubject(true);
        }
        if (email === "") {
            seterremail(true);
        }

        if (message === "") {
            seterrmessage(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter a valid phone number");
            }
        }


        if (
            firstName !== "" &&
            email !== "" &&
            subject !== "" &&
            message !== "" &&
            mobile !== "" &&
            phoneRegex.test(mobile)
        ) {
            postContacts(obj)
                .then((res) => {
                    console.log("res", res);
                    setmsg("Your enquiry has been successfully submitted")

                    setfirstName("");
                    setemail("")
                    setsubject("")
                    setmessage("")

                    // alert("Submited..!!")
                    // this.setState(
                    //   {
                    //     name: "",
                    //     email: "",
                    //     contact:"",
                    //     memberNo:"",
                    //     webSite: "",
                    //     message: "",
                    //     toaster: true,
                    //     msg: "Your enquiry has been successfully submitted",
                    //   }
                    //   //   () => {
                    //   //     setTimeout(this.setState({toaster: false}),4000);
                    //   //   }
                    // );
                })
                .catch((error) => {
                    console.log("error", error);
                    // setmsg(error.response.data.message)
                    // setTimeout(setmsg(null),4000);
                    // this.setState({});
                });

        }



    };

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Contact</span></li> */}
                    </ul>
                    <h2>Contact us</h2>
                </div>
            </section>



            <section className="about-five pt-120 pb-120">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
                    <p className="block-title__tagline">About Company</p>
                    
                </div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Learn Excel, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Why Schedule an Appointment with LearnXcel?</h4>
                                    {/* <div className="team-progress__bar">
                        <div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
                            <div className="team-progress__bar__text count-text" >77%</div>
                        </div>

                    </div> */}
                                    {/* <p className="about-five__text">Buying your first home is an exciting milestone, and we understand that it can also be overwhelming. Our dedicated team of experts is well-versed in the complexities of the Australian property market and can guide you through the process of securing your dream home. We'll help you navigate the maze of first home buyer grants, eligibility criteria, and loan options, ensuring a smooth and stress-free experience. </p> */}


                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Personalized Guidance
                                            </b>
                                            <br />
                                            Every student and professional has unique aspirations and goals.
                                            When you schedule an appointment with LearnXcel, you'll benefit from personalized
                                            guidance tailored to your specific needs and objectives.</li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Expert Advice
                                            </b>
                                            <br />
                                            Our experienced advisors have in-depth knowledge of various academic
                                            fields, institutions, and career pathways. They are here to provide expert advice and
                                            solutions to empower you.</li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Transparent Information
                                            </b><br />
                                            We prioritize transparency, ensuring that you have access to all
                                            the information you need to make informed decisions about your education and career. Our
                                            goal is to provide you with the knowledge and resources you need to embark on your
                                            journey with confidence.
                                        </li>


                                    </ul>



                                    {/* <p className="about-five__text pt-40"> Navigating the student visa process can be complex, but with LearnXcel, you're not alone. Our
                                        team of experts is here to provide guidance, support, and peace of mind at every stage of your
                                        journey, making your dream of studying abroad a reality.</p>


                                    <p className="about-five__text mt--40"> Don't hesitate to reach out to us for personalized assistance and detailed information about the
                                        student visa process specific to your chosen destination. Your educational adventure awaits, and
                                        we're here to help you every step of the way.</p> */}


                                </div>

                                {/* <div className="row row-gutter-y-30">
                    <div className="col-md-6">
                        <ul className="list-unstyled ml-0 about-two__list">
                            <li className='mt-4'>
                                <i className="fa fa-arrow-circle-right"></i>
                                <b>
                                Nsectetur cing elit.
                            </b>
                                </li>
                            <li className='mt-4'>
                                <i className="fa fa-arrow-circle-right"></i>
                                <b>
                                Suspe ndisse suscipit sagittis leo.
                            </b>
                                </li>
                            <li className='mt-4'>
                                <i className="fa fa-arrow-circle-right"></i>
                                <b>
                                Entum estibulum digni posuere.
                            </b>
                                </li>
                            <li className='mt-4'>
                                <i className="fa fa-arrow-circle-right"></i>
                                <b>
                                Donec tristique ante dictum rhoncus.
                            </b>
                                </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <div className="about-five__video">
                            <img src="assets/images/resources/about-5-v-1.png" alt="" />
                            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                                <i className="fa fa-play"></i>
                                <span className="ripple"></span>
                            </Link>
                        </div>
                    </div>
                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-120">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
    <p className="block-title__tagline">About Company</p>
    
</div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Learn Excel, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">What to Expect During Your Appointment</h4>
                                    {/* <div className="team-progress__bar">
        <div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
            <div className="team-progress__bar__text count-text" >77%</div>
        </div>

    </div> */}
                                    <p className="about-five__text">When you schedule a FREE appointment with LearnXcel, you can expect :</p>


                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4 d-flex align-items-center'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Personalized Guidance
                                            </b> */}
                                            {/* <br /> */}
                                            A one-on-one consultation with our experienced advisors to discuss your goals and
                                            aspirations</li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Expert Advice
                                            </b> */}
                                            {/* <br /> */}
                                            Personalized recommendations for courses, universities, and career paths based on your
                                            individual profile.</li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Transparent Information
                                            </b> */}
                                            {/* <br /> */}
                                            Guidance on the application process, including university selection, crafting compelling
                                            Statements of Purpose (SOPs), and navigating complex visa procedures
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Transparent Information
                                            </b> */}
                                            {/* <br /> */}
                                            Answers to any questions or concerns you may have about studying abroad or advancing
                                            your career.

                                        </li>


                                    </ul>



                                    {/* <p className="about-five__text pt-40"> Navigating the student visa process can be complex, but with LearnXcel, you're not alone. Our
                        team of experts is here to provide guidance, support, and peace of mind at every stage of your
                        journey, making your dream of studying abroad a reality.</p>


                    <p className="about-five__text mt--40"> Don't hesitate to reach out to us for personalized assistance and detailed information about the
                        student visa process specific to your chosen destination. Your educational adventure awaits, and
                        we're here to help you every step of the way.</p> */}


                                </div>

                                {/* <div className="row row-gutter-y-30">
    <div className="col-md-6">
        <ul className="list-unstyled ml-0 about-two__list">
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Nsectetur cing elit.
            </b>
                </li>
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Suspe ndisse suscipit sagittis leo.
            </b>
                </li>
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Entum estibulum digni posuere.
            </b>
                </li>
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Donec tristique ante dictum rhoncus.
            </b>
                </li>
        </ul>
    </div>
    <div className="col-md-6">
        <div className="about-five__video">
            <img src="assets/images/resources/about-5-v-1.png" alt="" />
            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                <i className="fa fa-play"></i>
                <span className="ripple"></span>
            </Link>
        </div>
    </div>
</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
<p className="block-title__tagline">About Company</p>

</div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Learn Excel, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">How to Schedule Your Appointment
                                    </h4>
                                    {/* <div className="team-progress__bar">
<div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
<div className="team-progress__bar__text count-text" >77%</div>
</div>

</div> */}
                                    <p className="about-five__text">Scheduling your FREE appointment with LearnXcel is a straightforward process, designed to make
                                        your journey to academic and career success as convenient as possible. Here's how you can book
                                        your appointment in just a few simple steps:</p>


                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                <span style={{ color: "#006b87" }}>STEP 1. </span>Click on the Link :
                                            </b>
                                            <br />
                                            Start by clicking on the provided link to access our appointment
                                            scheduling system</li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                <span style={{ color: "#006b87" }}>STEP 2. </span>Select an Available Date :
                                            </b>
                                            <br />
                                            Once you're on the scheduling platform, you'll see a calendar with
                                            available dates. Choose a date that works best for you, ensuring it aligns with your
                                            schedule.</li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                <span style={{ color: "#006b87" }}>STEP 3. </span> Choose a Time Slot :
                                            </b>
                                            <br />
                                            After selecting your preferred date, you'll have access to available time
                                            slots for that day. Pick a time that suits your availability. We offer a range of time options to
                                            accommodate various time zones and schedules.</li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                <span style={{ color: "#006b87" }}>STEP 4. </span>Enter Your Details:
                                            </b>
                                            <br />
                                            To confirm your appointment, we'll need some basic information. Please
                                            enter your name and contact number in the provided fields. This information helps us
                                            ensure that your appointment is scheduled accurately and that we can reach out to you if
                                            needed.
                                        </li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                <span style={{ color: "#006b87" }}>STEP 5. </span>Book Your Appointment:
                                            </b>
                                            <br />
                                            Once you've entered your details, simply click the "Book
                                            Appointment" or "Confirm" button. You'll receive a confirmation of your appointment via
                                            email or SMS, depending on your preferred method of contact.

                                        </li>





                                    </ul>



                                    <p className="about-five__text pt-40">And that's it! You've successfully scheduled your FREE appointment with LearnXcel. Our team will
                                        be ready to assist you during your appointment, providing you with the guidance and support you
                                        need to make informed decisions about your education and career.</p>


                                    <p className="about-five__text mt--40"> We look forward to connecting with you and helping you embark on your path to success. Don't
                                        hesitate to schedule your appointment today, and let's begin this journey together.</p>


                                </div>

                                {/* <div className="row row-gutter-y-30">
<div className="col-md-6">
<ul className="list-unstyled ml-0 about-two__list">
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Nsectetur cing elit.
</b>
</li>
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Suspe ndisse suscipit sagittis leo.
</b>
</li>
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Entum estibulum digni posuere.
</b>
</li>
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Donec tristique ante dictum rhoncus.
</b>
</li>
</ul>
</div>
<div className="col-md-6">
<div className="about-five__video">
<img src="assets/images/resources/about-5-v-1.png" alt="" />
<Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
<i className="fa fa-play"></i>
<span className="ripple"></span>
</Link>
</div>
</div>
</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="contact-info-one">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-telephone"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Have any question?</p>
                                    <a className="contact-info-one__link" href="tel:0741223375">+94741223375</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-email"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Write us email</p>
                                    <a className="contact-info-one__link" href="mailto:info@learnxcel.lk">info@learnxcel.lk</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-pin"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Visit anytime</p>
                                    <a className="contact-info-one__link" href="#">249 High Level Road  <br /> Maharagama</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-one pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact-one__content">
                                <div className="block-title">

                                    <h2 className="block-title__title">Feel free to get
                                        in touch</h2>
                                </div>
                                <p className="contact-one__text">At LearnXcel, we are dedicated to helping you achieve your educational dreams. Whether you're a
                                    student aspiring to study abroad or a professional seeking career advancement, we understand
                                    the importance of personalized guidance. We're here to be your partners on this journey, providing
                                    the support and expertise you need to make informed decisions about your education and career.
                                </p>


                            </div>
                        </div>


                        <div className="col-lg-8">
                            <form className="form-one contact-form-validated" onSubmit={contactUs} >
                                <div className="row row-gutter-y-20 row-gutter-x-20">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Full name" name="name"
                                            value={firstName}
                                            onChange={(e) => {
                                                setfirstName(e.target.value);
                                                seterrfirstName(false);
                                            }}
                                        />
                                        {errfirstName && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" placeholder="Email address" name="email"
                                            value={email}
                                            onChange={(e) => {
                                                setemail(e.target.value);
                                                seterremail(false);
                                            }} />
                                        {erremail && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone number" name="phone"
                                            value={mobile}
                                            onChange={(e) => {
                                                setmobile(e.target.value);
                                                seterrmobile("");
                                            }}
                                        />
                                        {errmobile && (
                                            <div className="error" style={{ color: "red" }}>
                                                {errmobile}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject"
                                            value={subject}
                                            onChange={(e) => {
                                                setsubject(e.target.value);
                                                seterrsubject(false);
                                            }}
                                        />
                                        {errsubject && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <textarea placeholder="Message" name="message"
                                            value={message}
                                            onChange={(e) => {
                                                setmessage(e.target.value);
                                                seterrmessage(false);
                                            }}
                                        ></textarea>
                                        {errmessage && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    {msg && (
                                        <span className="error" style={{ color: "green" }} >{msg}</span>
                                    )}
                                    <div className="col-md-12 d-flex align-items-center">

                                        <button type="submit" className="thm-btn">Send Message</button>

                                        {/* <h6 className="contact-info-one__link pl-20 pr-20">Or</h6> */}

                                        {/* <button type="submit" className="thm-btn2">Schedule Appointment</button> */}
                                        {/* <a href='https://calendly.com/learnexcel/30min' className='thm-btn' target='_blank'>Schedule An Appointment</a> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* <a href="https://maps.app.goo.gl/A5htM42WE5LKEsVY8" target="_blank" style={{ cursor: "pointer" }}>

                <div class="about-two__image2 d-none d-sm-block">
                    <img src={MAP1} alt="" style={{ width: "100%" }} />

                </div>

                <div class="about-two__image2 d-block d-sm-none">
                    <img src={MAP2} alt="" style={{ width: "100%" }} />

                </div>

            </a> */}

            <div className="google-map__default">
                <iframe title="template google map" src="https://maps.google.com/maps?q=249%20High%20Level%20Road%20Maharagama&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" className="map__default" allowfullscreen></iframe>
            </div>

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}

        </>
    )
}

export default Contact
import BGPH1 from '../../assets/images/backgrounds/bg-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { postContacts } from '../../Services/API/Post'

import MAP1 from '../../assets/images/contact/map1.png'
import MAP2 from '../../assets/images/contact/map2.png'


const Contact = () => {

    const [msg, setmsg] = React.useState(null)

    const [firstName, setfirstName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [mobile, setmobile] = useState("");

    const [errfirstName, seterrfirstName] = useState(false);
    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);
    const [erremail, seterremail] = useState(false);
    const [errmobile, seterrmobile] = useState("");


    React.useEffect(() => {
        if (msg !== null) {
            setTimeout(() => {
                setmsg(null);
            }, 6000);
        }

    }, [msg])

    const contactUs = (event) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


        event.preventDefault();

        const obj = {
            name: firstName,
            email: email,
            subject: subject,
            message: message,
            contactNumber: mobile,
        };

        console.log("obj", obj);

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (subject === "") {
            seterrsubject(true);
        }
        if (email === "") {
            seterremail(true);
        }

        if (message === "") {
            seterrmessage(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter a valid phone number");
            }
        }


        if (
            firstName !== "" &&
            email !== "" &&
            subject !== "" &&
            message !== "" &&
            mobile !== "" &&
            phoneRegex.test(mobile)
        ) {
            postContacts(obj)
                .then((res) => {
                    console.log("res", res);
                    setmsg("Your enquiry has been successfully submitted")

                    setfirstName("");
                    setemail("")
                    setsubject("")
                    setmessage("")

                    // alert("Submited..!!")
                    // this.setState(
                    //   {
                    //     name: "",
                    //     email: "",
                    //     contact:"",
                    //     memberNo:"",
                    //     webSite: "",
                    //     message: "",
                    //     toaster: true,
                    //     msg: "Your enquiry has been successfully submitted",
                    //   }
                    //   //   () => {
                    //   //     setTimeout(this.setState({toaster: false}),4000);
                    //   //   }
                    // );
                })
                .catch((error) => {
                    console.log("error", error);
                    // setmsg(error.response.data.message)
                    // setTimeout(setmsg(null),4000);
                    // this.setState({});
                });

        }



    };

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Contact</span></li> */}
                    </ul>
                    <h2>Privacy Policy</h2>
                </div>
            </section>


            <section className="about-five pt-120 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Introduction</h4>

                                    <p className="about-five__text">LearnXcel Pvt Ltd. ("LearnXcel," "we," "us," or "our") values the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our services, including our website, mobile applications, and any other services we provide (collectively, the "Services").
                                    </p>








                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Information We Collect</h4>

                                    <p className="about-five__text">
                                        We may collect personal information directly from you when you use our Services, including but not limited to your name, contact information, academic history, and other details necessary for our study abroad consultancy services.

                                    </p>

                                    <p className="about-five__text pt-1">
                                        We may also automatically collect certain information when you use our Services, such as your IP address, device type, browser type, and browsing behavior. This information helps us improve our Services and provide a better experience for our users.


                                    </p>








                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-80">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
    <p className="block-title__tagline">About Company</p>
    
</div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Learn Excel, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">How We Use Your Information</h4>
                                    {/* <div className="team-progress__bar">
        <div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
            <div className="team-progress__bar__text count-text" >77%</div>
        </div>

    </div> */}
                                    <p className="about-five__text">We use the information we collect to:
                                    </p>


                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4 d-flex align-items-center'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Personalized Guidance
                                            </b> */}
                                            {/* <br /> */}
                                            Provide our study abroad consultancy services, including matching students with suitable programs and institutions.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Expert Advice
                                            </b> */}
                                            {/* <br /> */}
                                            Communicate with you about our Services, including updates, promotions, and important notices.</li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Transparent Information
                                            </b> */}
                                            {/* <br /> */}
                                            Improve and optimize our Services, including analyzing user behavior and preferences.

                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                                Transparent Information
                                            </b> */}
                                            {/* <br /> */}
                                            Comply with legal obligations and enforce our policies.

                                        </li>


                                    </ul>



                                    {/* <p className="about-five__text pt-40"> Navigating the student visa process can be complex, but with LearnXcel, you're not alone. Our
                        team of experts is here to provide guidance, support, and peace of mind at every stage of your
                        journey, making your dream of studying abroad a reality.</p>


                    <p className="about-five__text mt--40"> Don't hesitate to reach out to us for personalized assistance and detailed information about the
                        student visa process specific to your chosen destination. Your educational adventure awaits, and
                        we're here to help you every step of the way.</p> */}


                                </div>

                                {/* <div className="row row-gutter-y-30">
    <div className="col-md-6">
        <ul className="list-unstyled ml-0 about-two__list">
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Nsectetur cing elit.
            </b>
                </li>
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Suspe ndisse suscipit sagittis leo.
            </b>
                </li>
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Entum estibulum digni posuere.
            </b>
                </li>
            <li className='mt-4'>
                <i className="fa fa-arrow-circle-right"></i>
                <b>
                Donec tristique ante dictum rhoncus.
            </b>
                </li>
        </ul>
    </div>
    <div className="col-md-6">
        <div className="about-five__video">
            <img src="assets/images/resources/about-5-v-1.png" alt="" />
            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                <i className="fa fa-play"></i>
                <span className="ripple"></span>
            </Link>
        </div>
    </div>
</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
<p className="block-title__tagline">About Company</p>

</div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Learn Excel, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Disclosure of Your Information</h4>
                                    {/* <div className="team-progress__bar">
<div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
<div className="team-progress__bar__text count-text" >77%</div>
</div>

</div> */}
                                    <p className="about-five__text">We may disclose your information to third parties in the following circumstances:

                                    </p>


                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4 d-flex align-items-center'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                Personalized Guidance
                            </b> */}
                                            {/* <br /> */}
                                            With your consent.

                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                Expert Advice
                            </b> */}
                                            {/* <br /> */}
                                            To comply with legal requirements or respond to lawful requests from government authorities.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            {/* <b>
                                Transparent Information
                            </b> */}
                                            {/* <br /> */}
                                            To protect the rights, property, or safety of LearnXcel, our users, or others.


                                        </li>



                                    </ul>

                                    <p className="about-five__text pt-40">We may also share non-personally identifiable information with third parties for marketing, advertising, or analytics purposes.


                                    </p>



                                    {/* <p className="about-five__text pt-40"> Navigating the student visa process can be complex, but with LearnXcel, you're not alone. Our
        team of experts is here to provide guidance, support, and peace of mind at every stage of your
        journey, making your dream of studying abroad a reality.</p>


    <p className="about-five__text mt--40"> Don't hesitate to reach out to us for personalized assistance and detailed information about the
        student visa process specific to your chosen destination. Your educational adventure awaits, and
        we're here to help you every step of the way.</p> */}


                                </div>

                                {/* <div className="row row-gutter-y-30">
<div className="col-md-6">
<ul className="list-unstyled ml-0 about-two__list">
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Nsectetur cing elit.
</b>
</li>
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Suspe ndisse suscipit sagittis leo.
</b>
</li>
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Entum estibulum digni posuere.
</b>
</li>
<li className='mt-4'>
<i className="fa fa-arrow-circle-right"></i>
<b>
Donec tristique ante dictum rhoncus.
</b>
</li>
</ul>
</div>
<div className="col-md-6">
<div className="about-five__video">
<img src="assets/images/resources/about-5-v-1.png" alt="" />
<Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
<i className="fa fa-play"></i>
<span className="ripple"></span>
</Link>
</div>
</div>
</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Data Security</h4>

                                    <p className="about-five__text">
                                        We take appropriate measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or method of electronic storage is 100% secure, so we cannot guarantee absolute security.

                                    </p>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Your Choices</h4>

                                    <p className="about-five__text">
                                        You have the right to access, update, or delete your personal information at any time. You may also opt out of receiving promotional communications from us by following the instructions provided in such communications.


                                    </p>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Changes to This Privacy Policy</h4>

                                    <p className="about-five__text">
                                        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other appropriate channels.



                                    </p>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-40">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Contact Us</h4>

                                    <p className="about-five__text">
                                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at info@learnxcel.lk.




                                    </p>

                                    <p className="about-five__text pt-10">
                                        Thank you for choosing LearnXcel Pvt Ltd. for your study abroad needs!
                                    </p>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >


            {/* <a href="https://maps.app.goo.gl/A5htM42WE5LKEsVY8" target="_blank" style={{ cursor: "pointer" }}>

                <div class="about-two__image2 d-none d-sm-block">
                    <img src={MAP1} alt="" style={{ width: "100%" }} />

                </div>

                <div class="about-two__image2 d-block d-sm-none">
                    <img src={MAP2} alt="" style={{ width: "100%" }} />

                </div>

            </a> */}


            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}

        </>
    )
}

export default Contact
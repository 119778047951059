import BGPH1 from '../../assets/images/backgrounds/bg-1.png'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import React from 'react'

const Careers = () => {

    let history = useNavigate()

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>

                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Student Essentials</span></li> */}
                    </ul>
                    <h2>Student Essentials</h2>
                </div>
            </section>
            <section className="about-three text-center pt-120 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="about-three__image mb-60 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <img src="assets/images/resources/about-3-1.png" alt="" />
                        </div> */}
                            <div className="about-three__content">
                                {/* <div className="block-title text-center">
                                    <p className="block-title__tagline">Student Essentials</p>
                                    <h2 className="block-title__title">Student Essentials</h2>
                                </div> */}

                                {/* <p className="about-three__text mt--10">Our website features a range of financial Student Essentialss designed to assist you in making informed decisions. These Student Essentialss cover various aspects of finance, including home loan affordability, mortgage repayments, borrowing capacity, and more. By utilizing these tools, you can gain valuable insights and estimate your financial commitments before making any decisions.</p> */}

                                {/* <div className="about-three__btns mt-40">
                                <Link to="#" className="thm-btn thm-btn--dark-hover">Let’s Get Started</Link>
                            </div> */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <section className="career-one pt-20 pb-120">
                <div className="container">
                    {/* <div className="block-title text-center">
                    <p className="block-title__tagline">What We’re Offering</p>
                    <h2 className="block-title__title">Checkout finlon new job <br /> opportunities </h2>
                </div> */}
                    <div className="row row-gutter-y-10">


                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                            <div className="career-one__item career-one__item2">
                                <div className="career-one__icon career-one__icon2">
                                    <i className="icon-management"></i>
                                </div>
                                <div className="career-one__content">
                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Student Health Cover</h3>
                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Ensuring your well-being during your educational journey is a top priority at LearnXcel. One
                                        of the essential aspects of your well-being is access to comprehensive student health
                                        cover. We understand that your health and safety are paramount, and having the right
                                        health insurance is not just a requirement but a lifeline.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>LearnXcel provides guidance on securing the right student health cover, making sure you're
                                        protected in case of any unexpected medical expenses. We'll help you understand the
                                        options available, find the plan that suits your needs, and navigate the enrollment process,
                                        ensuring you have the peace of mind to focus on your studies.</p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>

                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">

                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item career-one__item2">
                                <div className="career-one__icon career-one__icon2">
                                    <i className="icon-house"></i>
                                </div>
                                <div className="career-one__content">

                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Accommodation</h3>
                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Your accommodation plays a significant role in your overall academic experience. LearnXcel
                                        is here to help you find the ideal place to stay during your educational journey. Whether
                                        you're looking for on-campus housing, shared apartments, or host family arrangements, we
                                        offer guidance on your accommodation options.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>We'll provide insights into the most suitable choices based on your budget and
                                        preferences. Our goal is to ensure you have a comfortable and safe living environment,
                                        allowing you to focus on your studies and enjoy your time abroad.</p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">

                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item career-one__item2">
                                <div className="career-one__icon career-one__icon2">
                                    <i className="icon-bank-transfer"></i>
                                </div>
                                <div className="career-one__content">

                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Banking</h3>

                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Managing your finances while studying abroad is a critical aspect of your academic
                                        journey. LearnXcel offers guidance on establishing the necessary financial arrangements.
                                        We help you understand the banking options available in your host country and assist you
                                        in opening bank accounts and managing your finances efficiently.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Our goal is to ensure that your financial matters are in order, giving you peace of mind to
                                        concentrate on your education without worrying about monetary issues.</p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item career-one__item2">
                                <div className="career-one__icon career-one__icon2">
                                    <i className="icon-money-transfer"></i>
                                </div>
                                <div className="career-one__content">
                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Money Transfers
                                    </h3>

                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Efficiently managing your finances, including international money transfers, is essential
                                        while studying abroad. LearnXcel provides assistance in managing money transfers,
                                        allowing you to seamlessly handle your financial transactions across borders.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>We offer insights into the best ways to transfer funds, exchange currencies, and manage
                                        your financial resources. Our guidance ensures that you can access and use your funds
                                        without hassle, making your financial experience stress-free.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>At LearnXcel, we believe that a well-rounded support system is essential for a successful
                                        academic journey. These services, including student health cover, accommodation,
                                        banking, and money transfers, are integral to your overall well-being and experience while
                                        studying abroad. Let us help you take care of these practical aspects so you can fully
                                        embrace your academic adventure.
                                    </p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Small business loans for daily expenses</h2>
                                </div>
                                <p className="career-about__highlight">Duis irure dolor lipsum is simply free text available.</p>

                                <p className="career-about__text">There are many variations of passages of lorem ipsum available the
                                    majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum
                                    is simply free text available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default Careers
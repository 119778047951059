import BGSLI3 from '../../assets/images/backgrounds/cov-4.png'
import BGSLI4 from '../../assets/images/backgrounds/cov-5.png'
import BGCase11 from '../../assets/images/case/case-1-1.png'
import BGCase12 from '../../assets/images/case/case-1-2.png'
import BGCase13 from '../../assets/images/case/case-1-3.png'
import BGCase14 from '../../assets/images/case/case-1-4.png'
import BGBL21 from '../../assets/images/blog/blog-2-1.png'
import BGBL22 from '../../assets/images/blog/blog-2-2.png'
import BGBL23 from '../../assets/images/blog/blog-2-3.png'
import BGCta1 from '../../assets/images/backgrounds/cta-1-1-bg.jpg'
import AnimatedNumber from "animated-number-react"
import OwlCarousel from 'react-owl-carousel'
import { Link, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import BGVImg1 from "../../assets/images/backgrounds/video-bg-1-1.jpg";
import BGImgBe1 from "../../assets/images/backgrounds/benefit-bg-1-1.jpg";

import AVA from '../../assets/images/learn2.jpg'
import ABOUT from '../../assets/images/resources/homeAbout.png'

import Australia from '../../assets/images/country/Australia.png'
import Canada from '../../assets/images/country/Canada.png'
import Netherland from '../../assets/images/country/Netherland.png'
import NewZealand from '../../assets/images/country/NewZealand.png'
import Switzerland from '../../assets/images/country/Switzerland.png'
import UK from '../../assets/images/country/UK.png'

import TESTI1 from '../../assets/images/team/1.png'
import TESTI2 from '../../assets/images/team/2.png'
import TESTI3 from '../../assets/images/team/3.png'
import TESTI4 from '../../assets/images/team/4.png'

import TESTIMG from '../../assets/images/resources/testi.png'



const Home3 = () => {
    const activeRef = useRef(null);
    const [ytShow, setytShow] = useState(false);
    const [sticky, setSticky] = useState(false);
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const carouselRef = useRef();
    const [activeTab, setactiveTab] = useState("repair");

    const activeMenu = () => {
        if (path === "/" || path === "/home-02" || path === "/home-03") {
            setmenu({ home: true })
        } else if (path === "/about") {
            setmenu({ about: true })
        } else if (path === "/service" || path === "/servicedetails") {
            setmenu({ services: true })
        } else if (path === "/team" || path === "/teamdetails" || path === "/creditaudit" || path === "/creditrepair" || path === "/careers" || path === "/faqs" || path === "/applynow") {
            setmenu({ pages: true })
        } else if (path === "/personalloan" || path === "/loaneligibility" || path === "/homeloan" || path === "/homeloaneligibility" || path === "/compareEMI" || path === "/monthlyloan") {
            setmenu({ features: true })
        }
        else if (path === "/newsmain" || path === "/newsdetails") {
            setmenu({ news: true })
        }
        else if (path === "/contact") {
            setmenu({ contact: true })
        } else {
            setmenu({ home: true })
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? setSticky(true) : setSticky(false);
    }

    useEffect(() => {
        window.scroll(0, 0)
        activeMenu()
    }, [path]);


    const sliderOptionsTwo = {
        loop: true,
        items: 1,
        navText: ["", ""],
        margin: 0,
        dots: false,
        nav: true,
        // animateOut: "slideInRight",
        // animateIn: "slideInRight",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false
    }

    const sliderFeature = {
        loop: true,
        items: 1,
        navText: ["<span class='slider-one__carousel__btn__left'></span>", "<span class='slider-one__carousel__btn__left'></span>"],
        margin: 30,
        dots: true,
        nav: true,
        // animateOut: "slideOutDown",
        // animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            },
            1200: {
                margin: 30,
                items: 4
            }
        },
    }
    const sliderTestimonial = {
        loop: true,
        items: 1,
        navText: ["<span class='slider-one__carousel__btn__left'></span>", "<span class='slider-one__carousel__btn__left'></span>"],
        margin: 0,
        dots: true,
        nav: true,
        // animateOut: "slideOutDown",
        // animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false
    }

    const sliderCard = {
        loop: true,
        items: 1,
        navText: ["", ""],
        margin: 30,
        dots: true,
        nav: false,
        // animateOut: "slideOutDown",
        // animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            },
            1200: {
                margin: 30,
                items: 4
            }
        },

    }

    return (
        <>
            {/* <nav class={`main-menu main-menu--three ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
            <div class="container-fluid">
                <div class="main-menu__logo">
                    <Link to="/">
                        <img src="assets/images/logo-light-f.png" width="140" height="51" alt="Finlon" />
                    </Link>
                </div>
                <div class="main-menu__nav">
                    <ul class="main-menu__list">
                        <li class={`dropdown ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                            <ul>
                                <li> <Link to="/">Home One</Link> </li>
                                <li><Link to="home-02">Home Two</Link></li>
                                <li><Link to="home-03">Home Three</Link></li>
                                <li class="dropdown">
                                    <Link to="#">Header Styles</Link>
                                    <ul>
                                        <li><Link to="/">Header One</Link></li>
                                        <li><Link to="home-02">Header Two</Link></li>
                                        <li><Link to="home-03">Header Three</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class= {`about ${menu.about && "current"}`}>
                            <Link to="/about">About</Link>
                        </li>
                        <li class={`dropdown ${menu.services && "current"}`}><Link to="/services">Services</Link>
                            <ul>
                                <li><Link to="/service">Services 01</Link></li>
                                <li><Link to="/servicesdetails">Service Details</Link></li>
                            </ul>
                        </li>
                        <li class={`dropdown ${menu.pages && "current"}`}><Link to="#">Pages</Link>
                            <ul>
                                <li><Link to="/team">Team</Link></li>
                                <li><Link to="/teamdetails">Team Details</Link></li>
                                <li><Link to="/creditrepair">Credit Repair</Link></li>
                                <li><Link to="/creditaudit">Credit Audit</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/faqs">Faqs</Link></li>
                                <li><Link to="/applynow">Schedule An Appointment</Link></li>
                            </ul>
                        </li>
                        <li class={`dropdown ${menu.features && "current"}`}><Link to="#">Features</Link>
                            <ul>
                                <li><Link to="/personalloan">Personal Loan</Link></li>
                                <li><Link to="/loaneligibility">Loan Eligibility</Link></li>
                                <li><Link to="/homeloan">Home Loan</Link></li>
                                <li><Link to="/homeloaneligibility">Home Loan Eligibility</Link></li>
                                <li><Link to="/compareEMI">Compare EMI</Link></li>
                                <li><Link to="/monthlyloan">Monthly Calculator</Link></li>
                            </ul>
                        </li>
                        <li class={`dropdown ${menu.news && "current"}`}><Link to="#">News</Link>
                            <ul>
                                <li><Link to="/newsmain">News</Link></li>
                                <li><Link to="/newsdetails.">News Details</Link></li>
                            </ul>
                        </li>
                        <li class= {`contact ${menu.about && "current"}`}><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div class="main-menu__right">
                    <Link to="#" class="main-menu__toggler mobile-nav__toggler">
                        <i class="fa fa-bars"></i>
                    </Link>
                    <Link to="#" class="main-menu__search search-toggler">
                        <i class="icon-magnifying-glass"></i>
                    </Link>
                    <Link to="/applynow" class="thm-btn main-menu__btn">Schedule An Appointment</Link>
                    <Link to="tel:926668880000" class="main-menu__contact">
                        <span class="main-menu__contact__icon">
                            <i class="icon-phone"></i>
                        </span>
                        <span class="main-menu__contact__text">
                            <strong>92 666 888 0000</strong>
                            Mon to Fri: 9 am to 6 pm
                        </span>
                    </Link>
                </div>
            </div>
        </nav> */}
            <div className="stricky-header stricked-menu main-menu main-menu--three">
                <div className="sticky-header__content"></div>
            </div>
            <section className="slider-one slider-one--three">
                <OwlCarousel ref={carouselRef} className="thm-owl__carousel thm-owl__carousel--custom-nav owl-carousel owl-theme owl-dot-style-one" data-owl-nav-prev=".slider-one__carousel__btn__left" data-owl-nav-next=".slider-one__carousel__btn__right" {...sliderOptionsTwo} >
                    <div className="item">
                        <div className="slider-one__item">
                            <div className="slider-one__image" style={{ backgroundImage: `url(${BGSLI3})` }}>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-left">
                                        <h2 className="slider-one__title">Your Bridge to Global Education</h2>
                                        <p className="slider-one__text">LearnXcel - Your Premier Study Abroad Consultant</p>
                                        <div className="slider-one__btns">
                                            {/* <Link to="/contact" className="thm-btn">Let’s Get Started</Link> */}
                                            <a href='https://calendly.com/learnxcel' target='_blank' className="thm-btn" >Schedule An Appoinment</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="slider-one__item">
                            <div className="slider-one__image slider-one__image2" style={{ backgroundImage: `url(${BGSLI4})` }}>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-left">
                                        <h2 className="slider-one__title">Empowering Dreams Worldwide</h2>
                                        <p className="slider-one__text">LearnXcel - Your Premier Study Abroad Consultant</p>
                                        <div className="slider-one__btns">
                                            {/* <Link to="/contact" className="thm-btn">Let’s Get Started</Link> */}
                                            <a href='https://calendly.com/learnxcel' target='_blank' className="thm-btn" >Schedule An Appoinment</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                {/* <div className="slider-one__carousel__btn">
                    <button className="slider-one__carousel__btn__left" onClick={() => carouselRef.current.next()}>
                        <i className="fa fa-angle-left"></i>
                    </button>
                    <button className="slider-one__carousel__btn__right" onClick={() => carouselRef.current.prev()}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </div> */}
            </section>

            <section className="about-six pt-20 pb-20">
                <div className="container">
                    <div className="row row-gutter-y-60">
                        <div className="col-lg-6 order-2 order-sm-1">
                            <div className="about-six__image">

                                <img src={ABOUT} alt="" width={500} />

                                <div className="about-six__image__caption wow fadeInRight" data-wow-duration="1500ms">
                                    <h4 className="about-six__image__caption__year count-box">
                                        <span className="count-text" data-stop="20" data-speed="1500">5+</span>
                                    </h4>
                                    <p className="about-six__image__caption__text">Years of
                                        practicing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-sm-2">
                            <div className="about-six__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Your Expert Navigator in Student Migrations</h2>
                                </div>
                                <ul className="list-unstyled about-six__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Dynamic & Diversified
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Solution-Oriented
                                    </li>
                                </ul>
                                {/* <h3 className="about-six__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}

                                <p className="about-six__text">We are a team of individuals who understand the transformative power of education. Our mission is
                                    to be the bridge between your academic aspirations and the world's top educational institutions.</p>
                                <div className="about-six__author">
                                    <div className="about-six__author__image">
                                        <img src={AVA} alt="" width={76} />
                                    </div>
                                    <div className="about-six__author__name">
                                        Learnxcel
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>




            <section className="service-five pt-60 pb-20">
                <div className="container">
                    <div className="service-five__top">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">What We’re Offering</p>
                                    <h2 className="block-title__title">We provide best services for your credit</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* <p className="service-five__text">Lorem ipsum dolor sit amet nsectetur cing elituspe ndisse suscipit
                                    sagitis leo sit.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="case-one__carousel">
                            <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderFeature} data-owl-options='{
                            "loop": true,
                            "items": 1,
                            "autoplay": true,
                            "autoplayHoverPause": true,
                            "autoplayTimeout": 5000,
                            "smartSpeed": 500,
                            "nav": true,
                            "dots": true,
                            "margin": 30,
                            "responsive": {
                              "0": {
                                "items": 1
                              },
                              "768": {
                                "items": 2
                              },
                              "992": {
                                "items": 3
                              },
                              "1200": {
                                "items": 4
                              }
                            }
                          }'>
                                <div className="item">
                                    <Link to="/servicedetails">
                                        <div className="service-card-five">
                                            <div className="service-card-five__icon">
                                                <i className="icon-education"></i>
                                            </div>
                                            <h3 className="service-card-five__title">
                                                <Link to="/servicedetails">Comprehensive Counseling</Link>
                                            </h3>
                                            <p className="service-card-five__text">Unlocking Your Educational Potential</p>

                                        </div>
                                    </Link>
                                </div>
                                <div className="item">
                                    <Link to="/servicedetails2">
                                        <div className="service-card-five">
                                            <div className="service-card-five__icon">
                                                <i className="icon-bill"></i>
                                            </div>
                                            <h3 className="service-card-five__title">
                                                <Link to="/servicedetails2">Application Assistance</Link>
                                            </h3>
                                            <p className="service-card-five__text">Turning Aspirations Into Admissions
                                            </p>

                                        </div>
                                    </Link>
                                </div>
                                <div className="item">
                                    <Link to="/servicedetails3">
                                        <div className="service-card-five">
                                            <div className="service-card-five__icon">
                                                <i className="icon-portfolio"></i>
                                            </div>
                                            <h3 className="service-card-five__title">
                                                <Link to="/servicedetails3">Visa Guidance</Link>
                                            </h3>
                                            <p className="service-card-five__text">Seamless Visa Solutions
                                            </p>

                                        </div>
                                    </Link>
                                </div>
                                <div className="item">
                                    <Link to="/servicedetails4">
                                        <div className="service-card-five">
                                            <div className="service-card-five__icon">
                                                <i className="icon-user"></i>
                                            </div>
                                            <h3 className="service-card-five__title">
                                                <Link to="/servicedetails4">Pre-departure Briefings</Link>
                                            </h3>
                                            <p className="service-card-five__text">Preparing for Your Academic Adventure</p>

                                        </div>
                                    </Link>
                                </div>
                                <div className="item">
                                    <Link to="/servicedetails5">
                                        <div className="service-card-five">
                                            <div className="service-card-five__icon">
                                                <i className="icon-house"></i>
                                            </div>
                                            <h3 className="service-card-five__title">
                                                <Link to="/servicedetails5">Post-arrival Assistance</Link>
                                            </h3>
                                            <p className="service-card-five__text">A Smooth Transition to Your New Home</p>

                                        </div>
                                    </Link>
                                </div>
                                <div className="item">
                                    <Link to="/servicedetails6">
                                        <div className="service-card-five">
                                            <div className="service-card-five__icon">
                                                <i className="icon-money-transfer"></i>
                                            </div>
                                            <h3 className="service-card-five__title">
                                                <Link to="/servicedetails6">Show Money Assistance</Link>
                                            </h3>
                                            <p className="service-card-five__text">Empowering Financial Confidence
                                            </p>

                                        </div>
                                    </Link>
                                </div>
                                {/* <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div> */}
                            </OwlCarousel>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="service-card-five">
                                <div className="service-card-five__icon">
                                    <i className="icon-credit-cards"></i>
                                </div>
                                <h3 className="service-card-five__title">
                                    <Link to="/creditrepair">Comprehensive Counseling</Link>
                                </h3>
                                <p className="service-card-five__text">Lorem ipsum is free text used by neque est qui lorem.</p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="service-card-five">
                                <div className="service-card-five__icon">
                                    <i className="icon-audit"></i>
                                </div>
                                <h3 className="service-card-five__title">
                                    <Link to="/creditrepair">Application Assistance</Link>
                                </h3>
                                <p className="service-card-five__text">Lorem ipsum is free text used by neque est qui lorem.</p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="service-card-five">
                                <div className="service-card-five__icon">
                                    <i className="icon-portfolio"></i>
                                </div>
                                <h3 className="service-card-five__title">
                                    <Link to="/creditrepair">Visa Guidance</Link>
                                </h3>
                                <p className="service-card-five__text">Lorem ipsum is free text used by neque est qui lorem.</p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <div className="service-card-five">
                                <div className="service-card-five__icon">
                                    <i className="icon-education"></i>
                                </div>
                                <h3 className="service-card-five__title">
                                    <Link to="/creditrepair">Pre-departure Briefings</Link>
                                </h3>
                                <p className="service-card-five__text">Lorem ipsum is free text used by neque est qui lorem.</p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <div className="service-card-five">
                                <div className="service-card-five__icon">
                                    <i className="icon-education"></i>
                                </div>
                                <h3 className="service-card-five__title">
                                    <Link to="/creditrepair">Post-arrival Assistance</Link>
                                </h3>
                                <p className="service-card-five__text">Lorem ipsum is free text used by neque est qui lorem.</p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <div className="service-card-five">
                                <div className="service-card-five__icon">
                                    <i className="icon-education"></i>
                                </div>
                                <h3 className="service-card-five__title">
                                    <Link to="/creditrepair">Show Money Assistance</Link>
                                </h3>
                                <p className="service-card-five__text">Lorem ipsum is free text used by neque est qui lorem.</p>

                            </div>
                        </div> */}
                    </div>
                </div>
            </section>


            {/* <section
                className="video-one video-one--home pt-240 pb-120"
                style={{ backgroundImage: `url(${BGVImg1})`, marginTop: "-40px" }}
            >
                <div className="video-one__shape"></div>
                <div className="container">
                    <div className="row row-gutter-y-50">
                        <div className="col-lg-6">
                            <div className="video-one__content">
                               
                                <h3 className="video-one__title">
                                    Discover Your Dream Education Abroad
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ul className="list-unstyled video-one__list">
                                <li>
                                    <i className="icon-tick"></i>
                                    Australia
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    UK
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    Canada
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    New Zealand
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    USA
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    Malaysia
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="case-one pt-40 pb-60 ">
                <div className="container-fluid">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">Popular Destinations</p>
                        <h2 className="block-title__title">Discover Your Dream Education Abroad</h2>
                    </div>
                    <div className="case-one__carousel">
                        <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderFeature} data-owl-options='{
                            "loop": true,
                            "items": 1,
                            "autoplay": true,
                            "autoplayHoverPause": true,
                            "autoplayTimeout": 5000,
                            "smartSpeed": 500,
                            "nav": true,
                            "dots": true,
                            "margin": 30,
                            "responsive": {
                              "0": {
                                "items": 1
                              },
                              "768": {
                                "items": 2
                              },
                              "992": {
                                "items": 3
                              },
                              "1200": {
                                "items": 4
                              }
                            }
                          }'>
                            <div className="item">
                                <div className="case-card-one">
                                    {/* <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${Australia})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Australia</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    {/* <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${UK})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">UK</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    {/* <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${Canada})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Canada</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    {/* <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${NewZealand})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">New Zealand</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    {/* <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${Netherland})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Netherland</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    {/* <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${Switzerland})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Switzerland</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div> */}
                        </OwlCarousel>
                    </div>
                </div>
            </section>


            <section className="benefit-two pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            {/* <div className="benefit-two__image">
                                <img src="assets/images/resources/benefit-2-1.png" alt="" />
                            </div> */}
                        </div>
                        <div className="col-lg-7">
                            <div className="benefit-two__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">Popular Courses</p>
                                    <h2 className="block-title__title">Charting Your Course to Success</h2>
                                </div>
                                <div className="benefit-two__tab tabs-box">
                                    <ul className="list-unstyled benefit-two__tab__title tab-btns tab-buttons">
                                        <li className={`tab-btn ${activeTab === "repair" && "active-btn"}`} data-tab="#repair"
                                            onClick={() => {
                                                setactiveTab("repair");
                                            }}
                                        >
                                            <span>Foundation Programs</span>
                                        </li>
                                        <li className={`tab-btn ${activeTab === "audit" && "active-btn"}`} data-tab="#audit"
                                            onClick={() => {
                                                setactiveTab("audit");
                                            }}
                                        >
                                            <span>Diploma Programs</span>
                                        </li>
                                        <li className={`tab-btn ${activeTab === "credit" && "active-btn"}`} data-tab="#credit"

                                            onClick={() => {
                                                setactiveTab("credit");
                                            }}>
                                            <span>Bachelor's Degree Courses</span>
                                        </li>
                                        <li className={`tab-btn ${activeTab === "master" && "active-btn"}`} data-tab="#master"
                                            onClick={() => {
                                                setactiveTab("master");
                                            }}
                                        >
                                            <span>Master's Degree Courses</span>
                                        </li>
                                    </ul>
                                    <div className="benefit-two__tab__content tabs-content">
                                        <div className={`benefit-two__tab__content__inner tab ${activeTab === "repair" && "active-tab"}`} id="repair">
                                            {/* <p>There are many variations of passages of but the majority have in some form, by
                                                injected humou or words which don't look even slightly believable of but the
                                                majority have suffered.</p> */}
                                            <div className="benefit-two__tab__content__box">
                                                {/* <div className="benefit-two__tab__content__box__image">
                                                    <img src="assets/images/resources/benefit-2-2.png" alt="" />
                                                </div> */}
                                                <div className="benefit-two__tab__content__box__content">
                                                    <ul className="list-unstyled ml-0 about-two__list">
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Science
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Business
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Engineering
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Arts and Humanities
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Health Sciences
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Information Technology
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Social Sciences

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Design and Creative Arts


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Economics


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Environmental Studies
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`benefit-two__tab__content__inner tab ${activeTab === "audit" && "active-tab"}`} id="audit">
                                            {/* <p>There are many variations of passages of but the majority have in some form, by
                                                injected humou or words which don't look even slightly believable of but the
                                                majority have suffered.</p> */}
                                            <div className="benefit-two__tab__content__box">
                                                {/* <div className="benefit-two__tab__content__box__image">
                                                    <img src="assets/images/resources/benefit-2-2.png" alt="" />
                                                </div> */}
                                                <div className="benefit-two__tab__content__box__content">
                                                    <ul className="list-unstyled ml-0 about-two__list">

                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Business Administration

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Computer Science

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Culinary Arts

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Digital Marketing

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Early Childhood Education


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Graphic Design


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Hotel and Hospitality Management


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Nursing


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Renewable Energy


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Web Development and Design


                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`benefit-two__tab__content__inner tab ${activeTab === "credit" && "active-tab"}`} id="credit">
                                            {/* <p>There are many variations of passages of but the majority have in some form, by
                                                injected humou or words which don't look even slightly believable of but the
                                                majority have suffered.</p> */}
                                            <div className="benefit-two__tab__content__box">
                                                {/* <div className="benefit-two__tab__content__box__image">
                                                    <img src="assets/images/resources/benefit-2-2.png" alt="" />
                                                </div> */}
                                                <div className="benefit-two__tab__content__box__content">
                                                    <ul className="list-unstyled ml-0 about-two__list">
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Computer Science and Engineering

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Business Administration (BBA)

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Psychology

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Economics

                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Nursing


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Electrical Engineering


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Environmental Science


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Mechanical Engineering


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Political Science


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            International Relations


                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`benefit-two__tab__content__inner tab ${activeTab === "master" && "active-tab"}`} id="master">
                                            {/* <p>There are many variations of passages of but the majority have in some form, by
                                                injected humou or words which don't look even slightly believable of but the
                                                majority have suffered.</p> */}
                                            <div className="benefit-two__tab__content__box">
                                                {/* <div className="benefit-two__tab__content__box__image">
                                                    <img src="assets/images/resources/benefit-2-2.png" alt="" />
                                                </div> */}
                                                <div className="benefit-two__tab__content__box__content">
                                                    <ul className="list-unstyled ml-0 about-two__list">
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            MBA (Master of Business Administration)


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Data Science


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Public Health


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Finance


                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Artificial Intelligence



                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Marketing



                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Environmental Management




                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            International Business



                                                        </li>
                                                        <li>
                                                            <i className="fa fa-arrow-circle-right"></i>
                                                            Biotechnology



                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="benefit-two__fact">
                                    <div className="benefit-two__fact__icon">
                                        <i className="icon-credibility"></i>
                                    </div>
                                    <div className="benefit-two__fact__content">
                                        <div className="benefit-two__fact__title">
                                            <span className="odometer" data-count="6,800"><AnimatedNumber value={6800} duration={5000} formatValue={(v) => Math.round(v)} /></span>
                                        </div>
                                        <p className="benefit-two__fact__text">Successfully credit repaired</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="call-to-action-four text-center wow fadeInUp" data-wow-duration="1500ms">
                <div className="container">
                    <div className="call-to-action-four__inner">
                        <div className="call-to-action-four__shape-1"></div>
                        <div className="call-to-action-four__shape-2"></div>
                        <p className="call-to-action-four__text">
                            Join us and step into a world of limitless possibilities. <br />Reach out to our team today and let's chart
                            out your path to the future!

                        </p>
                        <div className="form-one mt-1" style={{
                            paddingBottom: "20px"
                        }}>
                            <Link to="/contact" className="thm-btn">Contact Now</Link>
                        </div>
                    </div>
                </div>
            </section> */}




            {/* <section>
                <div className="client-carousel @@extraclassNameName pb-60">
                    <div className="container">
                        <div className="block-title text-center">
                         
                            <h2 className="block-title__title">Your Path to Acclaimed Higher Education</h2>
                        </div>
                        <Swiper
                            className="thm-swiper__slider swiper-container"
                            modules={[Autoplay]}
                            spaceBetween={30}
                            slidesPerView={5}
                            autoplay={{ delay: 5000 }}
                            breakpoints={{
                                "0": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 2
                                },
                                "375": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 2
                                },
                                "575": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 3
                                },
                                "767": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 4
                                },
                                "991": {
                                    "spaceBetween": 15,
                                    "slidesPerView": 5
                                },
                                "1199": {
                                    "spaceBetween": 30,
                                    "slidesPerView": 5
                                }
                            }}
                        >
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </section> */}

            {/* <section className="team-about pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">professional team</p>
                        <h2 className="block-title__title">Your Path to Acclaimed Higher Education</h2>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-1.jpg" alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Connor Estrada</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-2.jpg" alt="Darrell Powell" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Darrell Powell</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-3.jpg" alt="Carolyn Love" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Carolyn Love</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* <section className="case-one pb-120 ">
                <div className="container-fluid">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">case study</p>
                        <h2 className="block-title__title">Discover our featured <br /> work studies</h2>
                    </div>
                    <div className="case-one__carousel">
                        <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderFeature} data-owl-options='{
                            "loop": true,
                            "items": 1,
                            "autoplay": true,
                            "autoplayHoverPause": true,
                            "autoplayTimeout": 5000,
                            "smartSpeed": 500,
                            "nav": true,
                            "dots": true,
                            "margin": 30,
                            "responsive": {
                              "0": {
                                "items": 1
                              },
                              "768": {
                                "items": 2
                              },
                              "992": {
                                "items": 3
                              },
                              "1200": {
                                "items": 4
                              }
                            }
                          }'>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section> */}



            <section className="testimonials-three pt-60">
                <div className="testimonials-three__shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="testimonials-three__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">our testimonials</p>
                                    <h2 className="block-title__title">What they are talking about our company</h2>
                                </div>
                                <div className="testimonials-three__carousel">
                                    <div className="testimonials-three__carousel__line"></div>
                                    <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderTestimonial} data-owl-options='{
                                        "loop": true,
                                        "items": 1,
                                        "autoplay": true,
                                        "autoplayHoverPause": true,
                                        "autoplayTimeout": 5000,
                                        "smartSpeed": 500,
                                        "nav": false,
                                        "dots": true,
                                        "margin": 30,
                                        "responsive": {
                                          "0": {
                                            "items": 1
                                          },
                                          "768": {
                                            "items": 1
                                          },
                                          "992": {
                                            "items": 1
                                          }
                                        }
                                      }'>
                                        <div className="item">
                                            <div className="testimonial-card-two">
                                                <div className="testimonial-card-two__content">
                                                    <div className="testimonial-card-two__icon">
                                                        <i className="icon-quotes"></i>
                                                    </div>
                                                    <div className="testimonial-card-two__text">From the very first meeting, I felt a genuine commitment from LearnXcel to turn my dreams into reality. Their expertise in guiding students to international universities is unparalleled. Thanks to them, I'm now pursuing my Masters in Australia with confidence and excitement. Highly recommended!</div>

                                                </div>
                                                <div className="testimonial-card__info">
                                                    {/* <div className="testimonial-card__image">
                                                        <img src={TESTI4} alt="@@title" />
                                                    </div> */}
                                                    <div className="testimonial-card__meta">
                                                        <div className="testimonial-card__stars">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <h3 className="testimonial-card__name">Sandana Gamage</h3>
                                                        <p className="testimonial-card__designation">Bangor University</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="testimonial-card-two">
                                                <div className="testimonial-card-two__content">
                                                    <div className="testimonial-card-two__icon">
                                                        <i className="icon-quotes"></i>
                                                    </div>
                                                    <div className="testimonial-card-two__text">Choosing LearnXcel was the best decision I made. They made the daunting process of applying abroad feel like a breeze.</div>

                                                </div>
                                                <div className="testimonial-card__info">
                                                    {/* <div className="testimonial-card__image">
                                                        <img src={TESTI3} alt="@@title" />
                                                    </div> */}
                                                    <div className="testimonial-card__meta">
                                                        <div className="testimonial-card__stars">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <h3 className="testimonial-card__name">Deepika Perera</h3>
                                                        <p className="testimonial-card__designation">Sheridan College</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="testimonial-card-two">
                                                <div className="testimonial-card-two__content">
                                                    <div className="testimonial-card-two__icon">
                                                        <i className="icon-quotes"></i>
                                                    </div>
                                                    <div className="testimonial-card-two__text">From the application process to settling in my university in UK, LearnXcel was with me at every step. I couldn't have done it without them!</div>

                                                </div>
                                                <div className="testimonial-card__info">
                                                    {/* <div className="testimonial-card__image">
                                                        <img src={TESTI1} alt="@@title" />
                                                    </div> */}
                                                    <div className="testimonial-card__meta">
                                                        <div className="testimonial-card__stars">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <h3 className="testimonial-card__name">Ransara Silva</h3>
                                                        <p className="testimonial-card__designation">London Metropolitan University</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight">
                            <div className="testimonials-three__image pt-100">
                                <img src={TESTIMG} alt="" width={902} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* <section className="team-about pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">professional team</p>
                        <h2 className="block-title__title">Meet the highly qualified <br /> team members</h2>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-1.jpg" alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Connor Estrada</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-2.jpg" alt="Darrell Powell" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Darrell Powell</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-3.jpg" alt="Carolyn Love" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Carolyn Love</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="work-process pt-40 pb-80">
                <div className="work-process__shape-1"></div>
                <div className="work-process__shape-2"></div>
                <div className="work-process__shape-3"></div>

                <div className="container">
                    <div className="block-title text-center">
                        {/* <p className="block-title__tagline">Student Visa Process</p> */}
                        <h2 className="block-title__title">Student Visa Process</h2>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="work-process__item">
                                <div className="work-process__icon">
                                    <i className="icon-email"></i>
                                </div>
                                <h3 className="work-process__title"><Link to="#">Receive an Offer of Admission</Link></h3>
                                {/* <p className="work-process__text">Lorem Ipsum is simply free dumy text of the printing and amet piscing
                                </p> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="work-process__item">
                                <div className="work-process__icon">
                                    <i className="icon-book"></i>
                                </div>
                                <h3 className="work-process__title"><Link to="#">Gather Essential Documents</Link></h3>
                                {/* <p className="work-process__text">Lorem Ipsum is simply free dumy text of the printing and amet piscing
                                </p> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="work-process__item">
                                <div className="work-process__icon">
                                    <i className="icon-bill"></i>
                                </div>
                                <h3 className="work-process__title"><Link to="#">Visa Application Submission
                                </Link></h3>
                                {/* <p className="work-process__text">Lorem Ipsum is simply free dumy text of the printing and amet piscing
                                </p> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <div className="work-process__item">
                                <div className="work-process__icon">
                                    <i className="icon-confirmation"></i>
                                </div>
                                <h3 className="work-process__title"><Link to="#">Visa Approval and Confirmation</Link></h3>
                                {/* <p className="work-process__text">Lorem Ipsum is simply free dumy text of the printing and amet piscing
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-three pt-40 pb-120">
                <div className="container">
                    <div className="service-three__top">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">What We’re Offering</p>
                                    <h2 className="block-title__title">Why Choose LearnXcel?</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="service-three__top__text">At LearnXcel, we take immense pride in our commitment to students and the results we've achieved. When you choose LearnXcel, you benefit from</p>

                            </div>
                        </div>
                    </div>
                    <OwlCarousel className="thm-owl__carousel service-three__carousel owl-dot-style-one owl-carousel owl-theme" {...sliderCard} data-owl-options='{
                        "margin": 30,
                        "items": 4,
                        "smartSpeed": 500,
                        "nav": true,
                        "dots": true,
                        "responsive": {
                          "0": {
                            "items": 1
                          },
                          "768": {
                            "items": 2
                          },
                          "992": {
                            "items": 3
                          },
                          "1200": {
                            "items": 4
                          }
                        }
                      }'>
                        <Link to="/">
                            <div className="item"
                            >
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-flowchart"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Proven Track Record</Link>
                                        </h3>
                                        <p className="service-card-three__text"
                                            style={{
                                                color: "#68686F"
                                            }}
                                        >We have a track record of successfully placing thousands of students
                                            in top institutions worldwide, helping them achieve their academic dreams.</p>
                                    </div>
                                    {/* <Link to="/servicedetails" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        {/* <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__inner">
                                    <div className="service-card-three__icon">
                                        <i className="icon-diamond"></i>
                                    </div>
                                    <h3 className="service-card-three__title">
                                        <Link to="/servicedetails">Wedding Loan</Link>
                                    </h3>
                                    <p className="service-card-three__text"  style={{
                                                color: "#68686F"
                                            }}>Car Loan provide low interest many variations of passages of
                                        lorem ipsum have some.</p>

                                </div>
                                <Link to="/servicedetails" className="service-card-three__link">
                                    <i className="fa fa-angle-right"></i>
                                </Link>
                            </div>
                        </div> */}
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-education"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Affiliations</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>Our strong partnerships with premier universities and colleges across the UK,
                                            Canada, and Australia ensure you have access to the best educational opportunities.</p>

                                    </div>
                                    {/* <Link to="/servicedetails2" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-user"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Personalized Approach</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>We understand the uniqueness of each student and provide
                                            tailor-made solutions to cater to your specific needs and goals.</p>

                                    </div>
                                    {/* <Link to="/servicedetails3" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-loan"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Transparent Services</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>Transparency is at the core of our service. You'll find no hidden costs
                                            or fees when you choose LearnXcel. Your dreams are our priority.</p>
                                    </div>
                                    {/* <Link to="/servicedetails4" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-growth"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Expert Guidance</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>Our experienced advisors have in-depth knowledge of various academic
                                            fields and institutions, ensuring you receive expert guidance tailored to your unique goals.
                                        </p>

                                    </div>
                                    {/* <Link to="/servicedetails5" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-bank"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Institution Selection</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>We help you choose from a wide range of universities and colleges
                                            around the world, each renowned for their academic excellence.</p>

                                    </div>
                                    {/* <Link to="/servicedetails6" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-briefcase"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Tailor-Made Solutions</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>We understand that each student is unique, and we provide
                                            personalized solutions that match your aspirations and academic interests.</p>

                                    </div>
                                    {/* <Link to="/servicedetails7" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="item">
                                <div className="service-card-three">
                                    <div className="service-card-three__inner">
                                        <div className="service-card-three__icon">
                                            <i className="icon-magnifying-glass"></i>
                                        </div>
                                        <h3 className="service-card-three__title">
                                            <Link to="/">Transparency</Link>
                                        </h3>
                                        <p className="service-card-three__text" style={{
                                            color: "#68686F"
                                        }}>LearnXcel prioritizes transparency, ensuring you have access to all the
                                            information you need to make informed decisions about your education.</p>
                                    </div>
                                    {/* <Link to="/servicedetails8" className="service-card-three__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </Link>
                     
                        {/* <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__inner">
                                    <div className="service-card-three__icon">
                                        <i className="icon-house"></i>
                                    </div>
                                    <h3 className="service-card-three__title">
                                        <Link to="/servicedetails">Property Loan</Link>
                                    </h3>
                                    <p className="service-card-three__text"  style={{
                                                color: "#68686F"
                                            }}>Car Loan provide low interest many variations of passages of
                                        lorem ipsum have some.</p>

                                </div>
                                <Link to="/servicedetails" className="service-card-three__link">
                                    <i className="fa fa-angle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__inner">
                                    <div className="service-card-three__icon">
                                        <i className="icon-briefcase"></i>
                                    </div>
                                    <h3 className="service-card-three__title">
                                        <Link to="/servicedetails">Business Loan</Link>
                                    </h3>
                                    <p className="service-card-three__text"  style={{
                                                color: "#68686F"
                                            }}>Car Loan provide low interest many variations of passages of
                                        lorem ipsum have some.</p>

                                </div>
                                <Link to="/servicedetails" className="service-card-three__link">
                                    <i className="fa fa-angle-right"></i>
                                </Link>
                            </div>
                        </div> */}
                    </OwlCarousel>

                </div>
            </section>


            {/* <section className="blog-three pb-120">
                <div className="container">
                    <div className="blog-three__top">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">Blogs</p>
                                    <h2 className="block-title__title">Meet the highly qualified <br /> team members</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="blog-three__btns">
                                    <Link to="newsdetails" className="thm-btn">View All News</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-12">
                            <div className="blog-card-two">
                                <div className="blog-card-two__image" style={{ backgroundImage: `url(${BGBL21})` }}>
                                </div>
                                <div className="blog-card-two__content">
                                    <div className="blog-card__meta">
                                        <Link to="newsdetails"><i className="far fa-user-circle"></i> by Admin</Link>
                                        <Link to="newsdetails"><i className="far fa-comments"></i> 2 Comments</Link>
                                    </div>
                                    <h3 className="blog-card-two__title"><Link to="newsdetails">Better changing to
                                        grow business faster </Link></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="blog-card-two">
                                <div className="blog-card-two__image" style={{ backgroundImage: `url(${BGBL22})` }}>
                                </div>
                                <div className="blog-card-two__content">
                                    <div className="blog-card__meta">
                                        <Link to="newsdetails"><i className="far fa-user-circle"></i> by Admin</Link>
                                        <Link to="newsdetails"><i className="far fa-comments"></i> 2 Comments</Link>
                                    </div>
                                    <h3 className="blog-card-two__title"><Link to="newsdetails">Better changing to
                                        grow business faster </Link></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="blog-card-two">
                                <div className="blog-card-two__image" style={{ backgroundImage: `url(${BGBL23})` }}>
                                </div>
                                <div className="blog-card-two__content">
                                    <div className="blog-card__meta">
                                        <Link to="newsdetails"><i className="far fa-user-circle"></i> by Admin</Link>
                                        <Link to="newsdetails"><i className="far fa-comments"></i> 2 Comments</Link>
                                    </div>
                                    <h3 className="blog-card-two__title"><Link to="newsdetails">Better changing to
                                        grow business faster </Link></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

          

            {/* <section className="call-to-action-one call-to-action-one--has-bottom-margin">
                <div className="container">
                    <div className="call-to-action-one__inner wow fadeInUp" data-wow-duration="1500ms">
                        <div className="call-to-action-one__bg" style={{ backgroundImage: `url(${BGCta1})` }}></div>

                        <h3 className="call-to-action-one__title">
                            Are you ready to get credit
                            repair services?
                        </h3>
                        <div className="call-to-action-one__btns">
                            <Link to="/applynow" className="thm-btn">Schedule An Appointment</Link>
                        </div>
                    </div>
                </div>
            </section> */}
            
            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}


        </>
    )
}

export default Home3
import BGPH1 from '../../../assets/images/backgrounds/bg-1.png'
import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'

const ServiceDetails = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Courses</span></li> */}
                    </ul>
                    <h2>Diploma Programs
                    </h2>
                </div>
            </section>
            <section className="service-details pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__item service-sidebar__item--menu">
                                    <ul className="service-sidebar__menu">
                                        <li ><Link to="/course1">FOUNDATION PROGRAMS</Link></li>
                                        <li className="active"><Link to="/course2">DIPLOMA PROGRAMS</Link></li>
                                        <li><Link to="/course3">BACHELOR'S DEGREE PROGRAMS </Link></li>
                                        <li><Link to="/course4">MASTER'S PROGRAMS</Link></li>

                                    </ul>
                                </div>
                                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                                    <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})` }}>
                                    </div>
                                    <div className="service-sidebar__contact">
                                        <div className="service-sidebar__icon">
                                            <i className="icon-phone-ringing"></i>
                                        </div>
                                        <h3 className="service-sidebar__title">Quick loan
                                            proccess</h3>
                                        <hr className="service-sidebar__separator" />
                                        <p className="service-sidebar__tagline">Talk to an expert</p>
                                        <a href='tel:0741223375' className="service-sidebar__phone">+94741223375</a>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {/* <div className="service-details__image">
                                <img src="assets/images/services/service-d-1-1.png" alt="" />
                                <div className="service-details__icon">
                                    <i className="icon-diamond"></i>
                                </div>
                            </div> */}
                            <div className="service-details__content">
                                <h3 className="service-details__title">DIPLOMA PROGRAMS</h3>
                                <p>Diploma programs offer a practical and specialized education, equipping you with in-demand skills
                                    for your chosen career path. Whether you're seeking to gain a competitive edge in the job market
                                    or planning to continue your education, LearnXcel is here to help you find the perfect diploma
                                    program. Our experienced counselors will guide you in selecting the right course and institution,
                                    ensuring you are well-prepared to achieve your academic and professional objectives.</p>
                                {/* <p>List of documents typically required for the loan. These documents may vary depending on the lender, loan type, and individual circumstances. It's always best to consult with us for the most accurate and up-to-date information regarding the required documentation.</p> */}









                                <div className="col-md-12 pt-20">


                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Business Administration


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Computer Science                                  </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Culinary Arts

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Digital Marketing
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Early Childhood Education
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Graphic Design
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Hotel and Hospitality Management
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Nursing
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Renewable Energy

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Web Development and Design

                                            </span>
                                        </li>
                                       
                                    </ul>
                                </div>

                                {/* <div className="col-md-12 pt-20">

                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Proof of identity (e.g., passport, driver's license)
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Proof of income (e.g., pay slips, tax returns, employment contract)
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Bank statements (usually the last three to six months)
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Proof of savings or deposit (e.g., bank statements, term deposit certificates)
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Proof of assets (e.g., property ownership, investments)
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Proof of liabilities (e.g., existing loans, credit card statements)
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Purchase contract or signed letter of offer
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Proof of address (e.g., utility bills, rental agreement)
                                            </span>
                                        </li>
                                    </ul>
                                </div> */}



                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <img src="assets/images/services/service-d-1-2.png" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="service-details__subtitle">Service benefits</h3>
                                        <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum.</p>
                                        <ul className="list-unstyled ml-0 service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Refresing to get such a personal touch.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Velit esse cillum eu fugiat pariatur.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                            {faqs.map((item, index) => (
                                                <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                    <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                        <h4>{item.question}</h4>
                                                        <span className="accrodion-icon"></span>
                                                    </div>
                                                    {index === clicked && <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Link to="/servicedetails" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default ServiceDetails
import BGPH1 from '../../assets/images/backgrounds/bg-1.png'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import React from 'react'

const Careers = () => {

    let history = useNavigate()

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>

                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Student Essentials</span></li> */}
                    </ul>
                    <h2>Services</h2>
                </div>
            </section>
            <section className="about-three text-center pt-120 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="about-three__image mb-60 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <img src="assets/images/resources/about-3-1.png" alt="" />
                        </div> */}
                            <div className="about-three__content">
                                {/* <div className="block-title text-center">
                                    <p className="block-title__tagline">Student Essentials</p>
                                    <h2 className="block-title__title">Student Essentials</h2>
                                </div> */}

                                {/* <p className="about-three__text mt--10">Our website features a range of financial Student Essentialss designed to assist you in making informed decisions. These Student Essentialss cover various aspects of finance, including home loan affordability, mortgage repayments, borrowing capacity, and more. By utilizing these tools, you can gain valuable insights and estimate your financial commitments before making any decisions.</p> */}

                                {/* <div className="about-three__btns mt-40">
                                <Link to="#" className="thm-btn thm-btn--dark-hover">Let’s Get Started</Link>
                            </div> */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <section className="career-one pt-20 pb-120">
                <div className="container">
                    {/* <div className="block-title text-center">
                    <p className="block-title__tagline">What We’re Offering</p>
                    <h2 className="block-title__title">Checkout finlon new job <br /> opportunities </h2>
                </div> */}
                    <div className="row row-gutter-y-10">


                        <div className="col-12 col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                            <div className="career-one__item">
                                <div className="career-one__icon">
                                    <i className="icon-flowchart"></i>
                                </div>
                                <div className="career-one__content">
                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Comprehensive Counseling</h3>
                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Our comprehensive counseling services are designed to be the compass on your academic journey. We take the time to understand your unique goals and ambitions. Whether you're looking for the perfect undergraduate program, a specialized master's degree, or a Ph.D. in your chosen field, we're here to help you map out your path to success</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>With our in-depth knowledge of various academic disciplines and institutions worldwide, we assist you in selecting the best courses and universities that align with your interests and career aspirations. Your dreams are our priority, and we're committed to guiding you toward the educational opportunities that will shape your future</p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>

                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">

                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item">
                                <div className="career-one__icon">
                                    <i className="icon-bill"></i>
                                </div>
                                <div className="career-one__content">

                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Application Assistance</h3>
                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>The application process to your dream university can be a complex and competitive journey. That's where we come in. LearnXcel offers full-scale application assistance, from start to finish. We help you shortlist universities that match your academic profile and career goals.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Our experienced team will also work with you to craft the perfect Statement of Purpose (SOP) that highlights your strengths and aspirations, making your application stand out. We provide invaluable guidance at every step, ensuring that you submit a compelling application that increases your chances of admission to your preferred institutions.</p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">

                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item">
                                <div className="career-one__icon">
                                    <i className="icon-portfolio"></i>
                                </div>
                                <div className="career-one__content">

                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Visa Guidance</h3>

                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Navigating the intricate visa processes for study destinations like the UK, Canada, and Australia requires expertise and an understanding of the latest regulations. LearnXcel's experienced team is your trusted partner in this journey, ensuring that you navigate the visa process with confidence.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>We guide you through every step, from gathering the required documents to preparing for interviews (if necessary). Our goal is to maximize your chances of visa approval, making your dream of studying abroad a reality.</p>
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item">
                                <div className="career-one__icon">
                                    <i className="icon-briefcase"></i>
                                </div>
                                <div className="career-one__content">
                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Pre-departure Briefings
                                    </h3>

                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>The excitement of studying abroad is often accompanied by questions and uncertainties. LearnXcel offers pre-departure briefings to ensure that you feel fully prepared and confident before setting out on your academic journey. These briefings cover essential topics such as adjusting to a new culture, understanding academic expectations, and managing practical aspects like accommodation and finances</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>We aim to provide you with the knowledge and resources you need to embark on your academic adventure with peace of mind.</p>

                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>At LearnXcel, we believe that a well-rounded support system is essential for a successful
                                        academic journey. These services, including student health cover, accommodation,
                                        banking, and money transfers, are integral to your overall well-being and experience while
                                        studying abroad. Let us help you take care of these practical aspects so you can fully
                                        embrace your academic adventure.
                                    </p> */}
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}

                            <div className="career-one__item">
                                <div className="career-one__icon">
                                    <i className="icon-house"></i>
                                </div>
                                <div className="career-one__content">
                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}
                                    <h3 className="career-one__title">Post-arrival Assistance
                                    </h3>

                                    {/* </a> */}

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Your journey doesn't end with your arrival in a new country; it's just the beginning. LearnXcel provides post-arrival assistance to help you transition smoothly into your new environment. We assist with essentials like finding accommodation, connecting with support networks, and ensuring that you feel comfortable and confident in your new surroundings.</p>

                                    <p className="career-one__text" style={{ color: "#68686F" }}>Our team is here to address any concerns you may have and to make your entry into your host country as stress-free as possible</p>

                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>At LearnXcel, we believe that a well-rounded support system is essential for a successful
                                        academic journey. These services, including student health cover, accommodation,
                                        banking, and money transfers, are integral to your overall well-being and experience while
                                        studying abroad. Let us help you take care of these practical aspects so you can fully
                                        embrace your academic adventure.
                                    </p> */}
                                </div>
                                {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                            </div>
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Small business loans for daily expenses</h2>
                                </div>
                                <p className="career-about__highlight">Duis irure dolor lipsum is simply free text available.</p>

                                <p className="career-about__text">There are many variations of passages of lorem ipsum available the
                                    majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum
                                    is simply free text available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Careers
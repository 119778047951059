import BGPH1 from '../../../assets/images/backgrounds/bg-1.png'
import { Link } from 'react-router-dom'
import React from 'react'

import Degree from '../../../assets/images/courses/Degree.png'
import Diploma from '../../../assets/images/courses/Diploma.png'
import Foundation from '../../../assets/images/courses/Foundation.png'
import Masters from '../../../assets/images/courses/Masters.png'


const Service = () => {
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Courses</span></li> */}
                    </ul>
                    <h2>Popular Courses</h2>
                </div>
            </section>
            <section className="about-three text-center pt-120 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="about-three__image mb-60 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <img src="assets/images/resources/about-3-1.png" alt="" />
                        </div> */}
                            <div className="about-three__content">
                                <div className="block-title text-center">
                                    {/* <p className="block-title__tagline">Calculator</p> */}
                                    <h2 className="block-title__title">Your Path to Academic Excellence</h2>
                                </div>

                                <p className="about-three__text mt--10">At LearnXcel, we understand that every student is unique, and so are your educational aspirations.
                                    Whether you're looking to embark on an undergraduate journey, further your knowledge with a
                                    master's degree, or pursue the highest level of academic achievement with a Ph.D., we're here to
                                    guide you on your path to academic excellence.
                                </p>

                                {/* <div className="about-three__btns mt-40">
                                <Link to="#" className="thm-btn thm-btn--dark-hover">Let’s Get Started</Link>
                            </div> */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="service-grid pt-120 pb-140">
                <div className="container">
                    <div className="row row-gutter-y-50">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/course1">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src={Foundation} alt="Auto Car Loan" />
                                        <Link to="/course1"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-book"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/course1">Foundation Programs
                                                </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>A foundation program is your gateway to higher education.</p>
                                            {/* <Link to="/course" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/course2">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src={Diploma} alt="Wedding Loan" />
                                        <Link to="/course2"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-flowchart"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/course2">Diploma Programs</Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>Diploma programs offer a practical and specialized education.
                                            </p>
                                            {/* <Link to="/course2" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/course3">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src={Degree} alt="Property Loan" />
                                        <Link to="/course3"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-programmer"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/course3">Bachelor's Degree Programs </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>A bachelor's degree is the foundation of your higher education journey. </p>
                                            {/* <Link to="/course3" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/course4">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src={Masters} alt="Education Loan" />
                                        <Link to="/course4"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-education"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/course4">Master's Programs</Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>A master's degree is the key to specialization and a deeper understanding of your chosen field.</p>
                                            {/* <Link to="/course4" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/*  */}







                    </div>
                </div>
            </section>
            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default Service

import BGPH1 from '../../assets/images/backgrounds/bg-1.png'
import { Link } from 'react-router-dom'
import React from 'react'

import STEP1 from '../../assets/images/resources/step1.png'
import STEP2 from '../../assets/images/resources/step2.png'


const Careers = () => {
    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>

                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Student Visa Process</span></li> */}
                    </ul>
                    <h2>Student Visa Process</h2>
                    {/* <h6 className='mt-20' style={{ color: "white", opacity: 0.8 }}>
                        Embarking on your international educational journey is an exciting step, but navigating the student
                        visa process can be a complex and sometimes daunting task. At LearnXcel, we are here to simplify
                        this process, making it as smooth and stress-free as possible. Here's a step-by-step overview of
                        the student visa process to help you understand what lies ahead

                    </h6> */}

                </div>
            </section>
            <section className="about-three text-center pt-40">
                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                           
                            <div className="about-three__content">
                                <div className="block-title text-center">
                                   
                                    <h2 className="block-title__title">Student Visa Process</h2>
                                </div>
                                <p className="about-three__text mt--10">Embarking on your international educational journey is an exciting step, but navigating the student
                                    visa process can be a complex and sometimes daunting task. At LearnXcel, we are here to simplify
                                    this process, making it as smooth and stress-free as possible. Here's a step-by-step overview of
                                    the student visa process to help you understand what lies ahead</p>
                               
                            </div>

                        </div>
                    </div>
                </div> */}
            </section>

            <section className="about-five pt-0 pb-120">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    
                                </div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Learn Excel, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    {/* <h4 className="team-progress__title">FIRST HOME BUYERS </h4> */}
                                    {/* <div className="team-progress__bar">
                                        <div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
                                            <div className="team-progress__bar__text count-text" >77%</div>
                                        </div>

                                    </div> */}
                                    {/* <p className="about-five__text">Buying your first home is an exciting milestone, and we understand that it can also be overwhelming. Our dedicated team of experts is well-versed in the complexities of the Australian property market and can guide you through the process of securing your dream home. We'll help you navigate the maze of first home buyer grants, eligibility criteria, and loan options, ensuring a smooth and stress-free experience. </p> */}

                                    {/* 
                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 1: Receive an Offer of Admission
                                            </b>
                                            <br />
                                            The first and crucial step is securing an offer of admission from your chosen educational
                                            institution. This offer is your ticket to beginning the student visa application process.                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 2: Ensure Financial Capacity
                                            </b>
                                            <br />
                                            Many countries require you to demonstrate your financial capacity to cover tuition fees, living
                                            expenses, and other costs while studying. Our team will guide you in preparing the necessary
                                            financial documents.                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 3: Gather Essential Documents
                                            </b><br />
                                            Your visa application will require a set of documents, which may include a valid passport,
                                            passport-sized photographs, academic transcripts, certificates, English language proficiency test
                                            scores (such as IELTS or TOEFL), health insurance, and a statement of purpose. We'll assist you in
                                            collecting and organizing these documents correctly.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 4: Visa Application Submission

                                            </b><br />

                                            Once you've gathered all the necessary documents and have your offer of admission, it's time to
                                            submit your student visa application. The application process and requirements can vary from one
                                            country to another, so our experienced team will ensure your application is complete and accurate.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 5: Biometrics and Interviews (if required)
                                            </b><br />

                                            Certain countries may request biometric data, interviews, or additional medical examinations as
                                            part of the visa application process. Our team will help you prepare for any required interviews and
                                            guide you through the process.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 6: Visa Processing Time

                                            </b><br />

                                            Visa processing times can vary widely depending on the country and other factors. We will keep
                                            you informed and updated about the progress of your visa application, helping you plan your next
                                            steps accordingly.

                                        </li>
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 7: Visa Approval and Confirmation
                                            </b><br />

                                            Upon receiving your visa approval, you'll need to accept the offer of admission from your chosen
                                            university and make the required tuition fee payment. We'll be there to guide you through this final
                                            stage, ensuring you are fully prepared for your academic journey.
                                        </li>
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Step 8: Pre-departure Preparation
                                            </b><br />

                                            Before leaving for your chosen study destination, you'll need to make necessary arrangements,
                                            such as booking your flight, securing accommodation, and understanding the rules and regulations
                                            of your host country. LearnXcel provides pre-departure briefings to ensure a confident and smooth
                                            transition.

                                        </li>
                                    </ul> */}

                                    <p className="about-five__text pt-40"> Embarking on your international educational journey is an exciting step, but navigating the student visa process can be a complex and sometimes daunting task. At LearnXcel, we are here to simplify this process, making it as smooth and stress-free as possible. Here's a step-by-step overview of the student visa process to help you understand what lies ahead</p>



                                    <div class="about-two__image2 d-none d-sm-block">
                                        <img src={STEP1} alt="" style={{ width: "100%" }} />

                                    </div>

                                    <div class="about-two__image2 d-block d-sm-none">
                                        <img src={STEP2} alt="" style={{ width: "100%" }} />

                                    </div>


                                    <p className="about-five__text pt-40"> Navigating the student visa process can be complex, but with LearnXcel, you're not alone. Our
                                        team of experts is here to provide guidance, support, and peace of mind at every stage of your
                                        journey, making your dream of studying abroad a reality.</p>


                                    <p className="about-five__text mt--40"> Don't hesitate to reach out to us for personalized assistance and detailed information about the
                                        student visa process specific to your chosen destination. Your educational adventure awaits, and
                                        we're here to help you every step of the way.</p>
                                </div>

                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <ul className="list-unstyled ml-0 about-two__list">
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Nsectetur cing elit.
                                            </b>
                                                </li>
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Suspe ndisse suscipit sagittis leo.
                                            </b>
                                                </li>
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Entum estibulum digni posuere.
                                            </b>
                                                </li>
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Donec tristique ante dictum rhoncus.
                                            </b>
                                                </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-five__video">
                                            <img src="assets/images/resources/about-5-v-1.png" alt="" />
                                            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                                                <i className="fa fa-play"></i>
                                                <span className="ripple"></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* <section className="career-one pt-120 pb-120">
            <div className="container">
                <div className="block-title text-center">
                    <p className="block-title__tagline">What We’re Offering</p>
                    <h2 className="block-title__title">Checkout finlon new job <br /> opportunities </h2>
                </div>
                <div className="row row-gutter-y-10">
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-management"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">Account Manager</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-designer"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">UI/UX Designer</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-programmer"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">Website Developer</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-flowchart"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">Project Management</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

            {/* <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Small business loans for daily expenses</h2>
                                </div>
                                <p className="career-about__highlight">Duis irure dolor lipsum is simply free text available.</p>

                                <p className="career-about__text">There are many variations of passages of lorem ipsum available the
                                    majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum
                                    is simply free text available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default Careers
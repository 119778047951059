import BGPH1 from '../../assets/images/backgrounds/bg-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { postContacts } from '../../Services/API/Post'

import MAP1 from '../../assets/images/contact/map1.png'
import MAP2 from '../../assets/images/contact/map2.png'


const Contact = () => {

    const [msg, setmsg] = React.useState(null)

    const [firstName, setfirstName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [mobile, setmobile] = useState("");

    const [errfirstName, seterrfirstName] = useState(false);
    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);
    const [erremail, seterremail] = useState(false);
    const [errmobile, seterrmobile] = useState("");


    React.useEffect(() => {
        if (msg !== null) {
            setTimeout(() => {
                setmsg(null);
            }, 6000);
        }

    }, [msg])

    const contactUs = (event) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


        event.preventDefault();

        const obj = {
            name: firstName,
            email: email,
            subject: subject,
            message: message,
            contactNumber: mobile,
        };

        console.log("obj", obj);

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (subject === "") {
            seterrsubject(true);
        }
        if (email === "") {
            seterremail(true);
        }

        if (message === "") {
            seterrmessage(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter a valid phone number");
            }
        }


        if (
            firstName !== "" &&
            email !== "" &&
            subject !== "" &&
            message !== "" &&
            mobile !== "" &&
            phoneRegex.test(mobile)
        ) {
            postContacts(obj)
                .then((res) => {
                    console.log("res", res);
                    setmsg("Your enquiry has been successfully submitted")

                    setfirstName("");
                    setemail("")
                    setsubject("")
                    setmessage("")

                    // alert("Submited..!!")
                    // this.setState(
                    //   {
                    //     name: "",
                    //     email: "",
                    //     contact:"",
                    //     memberNo:"",
                    //     webSite: "",
                    //     message: "",
                    //     toaster: true,
                    //     msg: "Your enquiry has been successfully submitted",
                    //   }
                    //   //   () => {
                    //   //     setTimeout(this.setState({toaster: false}),4000);
                    //   //   }
                    // );
                })
                .catch((error) => {
                    console.log("error", error);
                    // setmsg(error.response.data.message)
                    // setTimeout(setmsg(null),4000);
                    // this.setState({});
                });

        }



    };

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Contact</span></li> */}
                    </ul>
                    <h2>PTE & IELTS Coaching</h2>
                </div>
            </section>



            <section className="about-five pt-120 pb-20">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Mastery of Language Proficiency for Your Academic Success</h4>


                                    <p className="about-five__text">Language proficiency is a crucial component of your journey to academic excellence, especially when pursuing education in English-speaking countries like the UK, Canada, and Australia. At LearnXcel, we recognize the significance of achieving high scores in language proficiency tests such as PTE (Pearson Test of English) and IELTS (International English Language Testing System). These tests are not just requirements for admission; they are your key to confidently navigating a foreign academic landscape.</p>






                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-20">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Why Language Proficiency Matters</h4>


                                    <p className="about-five__text">Both PTE and IELTS assess your English language skills in listening, reading, writing, and speaking. The results of these tests determine your readiness to succeed in an English-speaking educational environment. High scores in PTE and IELTS can unlock opportunities, from admission to top-tier institutions to securing scholarships and enhancing your employability.</p>






                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="about-five pt-0 pb-20">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">

                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Why Choose LearnXcel for PTE & IELTS Coaching?</h4>


                                    <p className="about-five__text">At LearnXcel, we are your dedicated partners in achieving language proficiency excellence. We offer comprehensive coaching for both PTE and IELTS, ensuring that you are well-prepared to achieve the scores needed to pursue your academic ambitions.</p>

                                    <b className="about-five__text">Our Coaching Approach</b>

                                    <ul className="list-unstyled ml-0 about-two__list pb-40">
                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                1. Expert Instructors  :
                                            </b>
                                            <br />
                                            LearnXcel's experienced instructors are experts in language proficiency testing. They understand the intricacies of PTE and IELTS and will provide you with valuable insights and strategies for success.</li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                2. Personalized Training :
                                            </b>
                                            <br />
                                            We recognize that every student is unique and may have varying language proficiency needs. Our coaching is personalized to your specific strengths and areas for improvement, ensuring that you maximize your potential.</li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                3. Comprehensive Curriculum :
                                            </b>
                                            <br />
                                            Our coaching programs cover all facets of the tests, including reading, writing, listening, and speaking. You'll have access to a wide range of study materials and practice tests.</li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                4. Focused Preparation :
                                            </b>
                                            <br />
                                            We provide focused preparation to equip you with the skills and strategies needed to excel in both PTE and IELTS. Our goal is to help you confidently demonstrate your language proficiency.
                                        </li>



                                    </ul>



                                    <b className="about-five__text">The LearnXcel Advantage</b>
                                    <p className="about-five__text">Our coaching programs not only prepare you for the technical aspects of PTE and IELTS but also help you build confidence in your language abilities. We are dedicated to supporting you every step of the way, whether you are an aspiring student or a professional aiming to enhance your English language skills for career growth.</p>

                                    <p className="about-five__text">With LearnXcel, you're not just preparing for a language proficiency test; you're gaining the confidence and competence needed to excel in your academic journey and beyond. Join us today, and let's make language proficiency an asset on your path to success.

                                    </p>

                                    <p className="about-five__text">At LearnXcel, we're not just coaching you for a test; we're empowering you to communicate, excel, and thrive in English-speaking academic environments. Our PTE and IELTS coaching programs are designed to help you unlock the doors to quality education and professional success.</p>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section >

          

            {/* <div className="google-map__default">
                <iframe title="template google map" src="https://maps.google.com/maps?q=No 78/29A Wackwella Road,Galle 80000&t=&z=15&ie=UTF8&iwloc=&output=embed" className="map__default" allowfullscreen></iframe>
            </div> */}

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}

        </>
    )
}

export default Contact